import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddReferral from './AddReferral'
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
//import AddIcon from '@material-ui/icons/Add';
// Generate Order Data



const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));




const TopSection1 = ({referrals}) => {
    const classes = useStyles();
  
    if(referrals){return (
        
      <React.Fragment>
      <Title>Total Pending Referrals</Title>
      <Typography component="p" variant="h4">
        {referrals.length}
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        on {new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()}
      </Typography>
      {/*<div>
      <Button component={Link} to="/add-referrals" color="secondary">Add Referral</Button>
      
      </div>*/}
    </React.Fragment>
    )}else{
      return (
        <Typography>Loading ..</Typography>
      )
    }
    
  }

  const mapStateToProps = (state) => {
    // console.log(state);
    return {
      referrals: state.firestore.ordered.referrals,
      auth: state.firebase.auth,
      //notifications: state.firestore.ordered.notifications
    }
  }
  


  export default compose(
    connect(mapStateToProps, ),
    firestoreConnect((props) => {
      if (!props.auth.uid) return []
      return [
        {
          collection: 'referrals',
          where: [
            ['authorId', '==', props.auth.uid],
            ['status', '==', 'Pending']
          ]
        }
      ]
    }
    )
  )(TopSection1)


import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {
    Select,
    InputLabel,
    
    FormControl,
    
  } from "@material-ui/core";
  import firebase from '../../config/fbConfig';
  import Box from '@material-ui/core/Box';
  import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
  import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux'
import { signUp } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'
//import { useFormik } from 'formik';
import { Formik,Form } from 'formik';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core';
import { FormikTextField } from 'formik-material-fields';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {

    MDBRow,
    MDBCol,
   
    MDBBtn,


  } from 'mdbreact';

  const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },
    textField: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',            
      paddingBottom: 0,
      marginTop: 0,
      background:"red",
      fontWeight: 500
  },
  input: {
    '&::before': {
      borderBottom:"1px solid white"
    }
  }
  });

const validationSchema = yup.object({
    fname: yup
    .string('Enter your First Name')
    .required('First Nameis required'),
    lname: yup
    .string('Enter your Last Name')
    .required('Last Nameis required'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
      password: yup
      .string('Enter your password')
      .min(6, 'ERROR: password is too short')
      //.min(10, 'Contact number should be of minimum 10 digits length')
      .required('Password is required'),
    mobile: yup
      .string('Enter your contact number')
      .min(10, 'Contact number should be of minimum 10 digits length')
      .min(10, 'Contact number should be of minimum 10 digits length')
      .required('Contact number is required'),
  
      city: yup
      .string('Enter your City Name')
      
      .required('College Name is required'),
      college: yup
      .string('Enter your College Name')
      
      .required('College Name is required'),
      stream: yup
      .string('Enter branch name')
 
      .required('Branch name is required'),
      year: yup
      .string('Enter year of passout')
 
      .required('year of passout is required'),
   
    
 
      
  });
 
const PhoneField = (props) => {
    //const history = useHistory();
    const { auth, authError } = props;
    const classes = useStyles();
    const [verify,setVerify]=React.useState(false)
    const [buttontext, setButtontext] = React.useState('VERIFY');
    const paperStyle={padding :20,height:'70vh',width:280, margin:"60px auto"}
    const avatarStyle={backgroundColor:'#6d7f9f'}   //  #3370bd
    const btnstyle={marginTop:'28px ',backgroundColor:'#6d7f9f'}

     // console.log("www",props);
    const [user, setUser] = React.useState({
        otp:"",
        
      });
      const [valid, setInvalid] = React.useState(false);
      const { otp,name,mobile,email,college,department,designation,question1,question2,question3} = user;
      const handleSubmit = () => {
        //console.log("image64",image64);
        setButtontext('Checking...');
        const verifyOtp = firebase.functions().httpsCallable('verify');
      
        verifyOtp({
            mobile: mobile,
            otp:parseInt(otp)
           
          }).
          then((res) => {
            setOpen(false);
             setVerify(true)
             let body = {
                fullname: name,
                mobilenumber: mobile,
                //profileImage: image64,
                email: email,
                college: college,
                designation: designation,
                department: department,
                question1: question1,
                question2: question2,
                question3: question3

            };
            props.guestReferral(body)
             console.log("res",body)
            
          })
          .catch((error) => {
            setButtontext('VERIFY');
            setInvalid(true)
            console.log('error',error)
          })
          //console.log("wwq",filteredData[0].otp);
          //console.log("wwq",otp);
          // your submit logic
      }
 
      // Timer 
 
    const [counter, setCounter] = React.useState(59);
    React.useEffect(() => {
        const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);
      const handleChange  = e => {
          console.log(e.target.name);
          setUser({ ...user, [e.target.name]: e.target.value });
        };
        //const [open, setOpen] = React.useState(false);
        const handleClose = () => {
          setOpen(false);
        };
        const [open, setOpen] = React.useState(false);
        const sendOtp = firebase.functions().httpsCallable('sample');
        const resendOtp = () => {
            sendOtp({
                mobile: mobile
                //otp:1552
               
              }).
              then((res) => {
                 console.log("res",res)
              })
              .catch((error) => {
                console.log('error',error)
              })
            /*sendOtp({
                mobile: mobile,
               
              }).
              then((res) => {
                 console.log("res",res)
              })
              .catch((error) => {
                console.log('error',error)
              })*/
          //setOpen(true);
        };
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Verify Mobile Number</DialogTitle>
        <DialogContent>
          <DialogContentText>
   
                
               
                <Box color="text.secondary">
                  <Typography variant="body2">
                      Enter OTP Sent to your mobile number {mobile?mobile:""}
                  </Typography>  
                </Box>    
              
          </DialogContentText>
        
           
 
                <ValidatorForm
                onSubmit={handleSubmit}>
                 <TextValidator
                    label="Enter 4 Digit OTP"
                    onChange={handleChange}
                    variant="outlined"
                    inputProps={{ maxLength: 4, minLength: 4 }}
                    name="otp"
                    size="small"
                    type="number"
                    fullWidth
                    validators={['required']}
                    errorMessages={['OTP is required']}
                    value={user.otp}
                 />
                {valid &&
                <Typography style={{color:"red"}}>Invalid Otp</Typography>
                }
               <Button type='submit' color='primary' variant="contained" style={btnstyle} fullWidth>{buttontext}</Button>
            </ValidatorForm>
                <Box mt={3} ><Typography fontWeight={500} align="center" color='textSecondary'> Resend OTP in <span style={{color:"green",fontWeight:"bold"}}> 00:{counter}</span> </Typography></Box>
              
                  <Typography align="center">  
                    {counter == "0" &&
                     <Button style={{marginLeft:"5px"}} onClick={resendOtp}> Resend OTP </Button>
                    }
                    
                  
                  </Typography> 
            
            
        </DialogContent>
        <DialogActions>
         {/* <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Subscribe
    </Button>*/}
        </DialogActions>
      </Dialog>
  <Formik
      enableReinitialize={true}
      initialValues={{
        proficiency:'',
        fname:'',
        lname:'',
        email:'',
        password:'',
        mobile:'',
        city:'',
      
        college:'',
        stream:'',
       
        year:'',
     
       

      }}
      validationSchema= {validationSchema}
      onSubmit={(values, {resetForm}) => {
     
          console.log("values",values)
          //props.createLead(values);
          props.signUp(values);
         // resetForm({ values: ''});
          //return;
        //props.updateLead(values);
      
        /*toast.success('Successfully Added Prospects', {
            closeButton: false
          });*/
        //alert("values:" + JSON.stringify(values));
      }}
      
    >
      <Form>
      <MDBRow >
                 <MDBCol md="6">
        <FormControl fullWidth>
      
         <FormikTextField
              name="fname"
              label="First Name"
              margin="normal"
              fullWidth
              InputProps={{
                className: classes.input,
                style: {
                    color: "white"
                }
            }}
            />
        </FormControl>
        </MDBCol>
            <MDBCol md="6">
        <FormControl fullWidth>
         
      <FormikTextField
           name="lname"
           label="Last Name"
           margin="normal"
           fullWidth
           InputProps={{
            className: classes.input,
            style: {
                color: "white"
            }
        }}
         />
     </FormControl>
</MDBCol></MDBRow>
        <FormikTextField
              name="email"
              label="Email"
              margin="normal"
              InputProps={{
                className: classes.input,
                style: {
                    color: "white",
                    borderBottomColor:"yellow"
                }
            }}
              fullWidth
            />
                     <FormikTextField
              name="password"
              label="Password"
              margin="normal"
              type="password"
              InputProps={{
                className: classes.input,
                style: {
                    color: "white"
                }
            }}
              fullWidth
            />
             <FormikTextField
              name="mobile"
              label="Mobile"
              margin="normal"
              type="number"
              InputProps={{
                className: classes.input,
                style: {
                    color: "white"
                }
            }}
              fullWidth
            />
                   <FormikTextField
              name="city"
              label="City"
              margin="normal"
              InputProps={{
                className: classes.input,
                style: {
                    color: "white"
                }
            }}
              fullWidth
            />
     
              <FormikTextField
              name="college"
              label="College"
              margin="normal"
              InputProps={{
                className: classes.input,
                style: {
                    color: "white"
                }
            }}
              fullWidth
            />
            
              <FormikTextField
              name="stream"
              label="Branch / Stream"
              margin="normal"
              InputProps={{
                className: classes.input,
                style: {
                    color: "white"
                }
            }}
              fullWidth
            />
              <FormikTextField
              name="year"
              label="Passout Year"
              margin="normal"
              InputProps={{
                className: classes.input,
                style: {
                    color: "white"
                }
            }}
              fullWidth
            />




  
<div className='text-center mt-4 black-text'>

                        <MDBBtn color='indigo' type="submit">Register</MDBBtn>
                        <div className="center red-text">
              { authError ? <p>{authError}</p> : null }
            </div>
              
                        
                        <hr className='hr-light' />
                        </div>
      </Form>
    </Formik>
 </div>
 );
};

//export default withStyles(styles)(PhoneField);
const mapStateToProps = (state) => {
    return {
      auth: state.firebase.auth,
      authError: state.auth.authError
    }
  }
  
  const mapDispatchToProps = (dispatch)=> {
    return {
      signUp: (creds) => dispatch(signUp(creds))
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(PhoneField)


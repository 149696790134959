export const createLead = (lead) => {
    
    return (dispatch, getState, {getFirestore}) => {
      const firestore = getFirestore();
      const profile = getState().firebase.profile;
      const authorId = getState().firebase.auth.uid;
      //console.log("lead",profile);
      firestore.collection('leads').add({
        ...lead,
        authorFirstName: profile.fname,
        authorLastName: profile.lname,
        authorId: authorId,
        createdAt: new Date()
      }).then(() => {
        dispatch({ type: 'CREATE_LEAD_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'CREATE_LEAD_ERROR' }, err);
      });
    }
  };

  export const updateLead = (lead) => {
    
    return (dispatch, getState, {getFirestore}) => {
      const firestore = getFirestore();
      const profile = getState().firebase.profile;
      const authorId = getState().firebase.auth.uid;
      console.log("lead",lead);
      
      firestore.collection('leads').doc(lead.id).set({
        ...lead,
        authorFirstName: profile.fname,
        authorLastName: profile.lname,
        authorId: authorId,
        updatedAt: new Date()
      }).then(() => {
        dispatch({ type: 'UPDATE_LEAD_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'UPDATE_LEAD_ERROR' }, err);
      });
    }
  };
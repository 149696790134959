const initState = {
    authError: null
  }
  
  const authReducer = (state = initState, action) => {
    switch(action.type){
      case 'LOGIN_ERROR':
        console.log('login error');
        return {
          ...state,
          authError: 'Login failed'
        }
        case 'SET_CURRENT_USER':
            return {
              ...state,
              currentUser: action.payload
            }
      case 'LOGIN_SUCCESS':
        console.log('login success');
        return {
          ...state,
          authError: null
        }

        case 'UPDATE_PROFILE_SUCCESS':
            console.log('signout success');
            return state;
      case 'SIGNOUT_SUCCESS':
        console.log('signout success');
        return state;

    case 'FORGOT_SUCCESS':
            console.log('forgot success');
            return state;

            case 'FORGOT_ERROR':
                console.log('signup error')
                return {
                  ...state,
                  authError: action.err.message
                }
  
      case 'SIGNUP_SUCCESS':
        console.log('signup success')
        return {
          ...state,
          authError: null
        }
  
      case 'SIGNUP_ERROR':
        console.log('signup error')
        return {
          ...state,
          authError: action.err.message
        }
  
      default:
        return state
    }
  };
  
  export default authReducer;

import React, { Component } from 'react'
import { MDBRow } from 'mdbreact';
import AdminCardSection1 from './sections/AdminCardSection1';
import AdminCardSection2 from './sections/AdminCardSection2';
import TableSection from './sections/TableSection';
import BreadcrumSection from './sections/BreadcrumSection';
import ChartSection1 from './sections/ChartSection1';
import ChartSection2 from './sections/ChartSection2';
import Leads from '../leads/LeadList';
import ModalSection from './sections/ModalSection';
import {connect} from 'react-redux'
import Sidemenu from './sideNavigation'
import Topmenu from './topNavigation'


class Dashboard extends Component {
render() {
    //console.log(this.props);
    const { leads } = this.props;
  return (
    <React.Fragment>
         <Topmenu></Topmenu>
        <Sidemenu>

        </Sidemenu>
       
      <BreadcrumSection />
      <Leads  leads={leads}/>
      <AdminCardSection1 />
      <ChartSection1 />
      <TableSection />
      <ChartSection2 />
      <MDBRow className="mb-4">
        
          <ModalSection />
      </MDBRow>
      <AdminCardSection2 />
    </React.Fragment>
  )
}
}
const mapStateToProps = (state) => {
    return {
        leads: state.lead.leads
    }
}

export default connect(mapStateToProps)(Dashboard);
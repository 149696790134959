import React from 'react'
import { NavLink } from 'react-router-dom';
import {
    MDBNavbarNav,
    MDBNavItem,
    MDBNavLink,
    MDBBtn
  } from 'mdbreact';
  import Avatar from 'react-avatar';
  import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'


const SignedInLinks = (props) => {
  return (
    <div>
            <MDBNavbarNav right pills color="unique">
                    <MDBNavItem active>
                      <MDBNavLink to='#!'>Add Leads</MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBBtn onClick={props.signOut}>Logout</MDBBtn>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to='#!'><Avatar name="Wim Mostmans" size="50" textSizeRatio={1.75} round={true}/> </MDBNavLink>
                    </MDBNavItem>
            </MDBNavbarNav>
    </div>
  )
}
const mapDispatchToProps = (dispatch) => {
    return {
      signOut: () => dispatch(signOut())
    }
  }

  export default connect(null, mapDispatchToProps)(SignedInLinks)
//export default SignedInLinks
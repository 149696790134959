import React from 'react';
//import Link from '@material-ui/core/Link';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddForm from './AddProspectForm'
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import { useHistory } from "react-router-dom";
//import AddIcon from '@material-ui/icons/Add';
// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

const rows = [
  createData(0, '16 Mar, 2019', 'Elvis Presley', 'Tupelo, MS', 'VISA ⠀•••• 3719', 312.44),
  createData(1, '16 Mar, 2019', 'Paul McCartney', 'London, UK', 'VISA ⠀•••• 2574', 866.99),
  createData(2, '16 Mar, 2019', 'Tom Scholz', 'Boston, MA', 'MC ⠀•••• 1253', 100.81),
  createData(3, '16 Mar, 2019', 'Michael Jackson', 'Gary, IN', 'AMEX ⠀•••• 2000', 654.39),
  createData(4, '15 Mar, 2019', 'Bruce Springsteen', 'Long Branch, NJ', 'VISA ⠀•••• 5919', 212.79),
];

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));




const Order = ({leads}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
  
    const handleClickOpen1 = (e) =>{ 
      //history.push("/about");
      console.log("eee",e);
    }
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    console.log(leads);
    return (
        
        <React.Fragment>
         <Grid container spacing={3}>
       
       <Grid item white xs={6}>
          <Title color="text.primary">Prospect History</Title> 
            
          <Fab component={Link} to="/add-prospects" color="primary" aria-label="add">
        <AddIcon />
      </Fab>
       </Grid>
       <Grid item white xs={6}>
        
       </Grid>

    
       
     </Grid>
    
  
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Mobile</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <div>
      
   
    </div>
        <TableBody>
        { leads && leads.map(lead => {
          return (
            <TableRow key={lead.id}>
            <TableCell>{lead.name}</TableCell>
            <TableCell>{lead.email}</TableCell>
            <TableCell>{lead.mobile}</TableCell>
            <TableCell><Button color="secondary">Pending</Button></TableCell>
           
            <TableCell align="right"><Fab color="primary" component={Link} to={'/prospects/edit/'+lead.id} aria-label="edit">
            <EditIcon color="text.primary"/>
</Fab></TableCell>
          </TableRow>
          )
        })}  
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        {/*<Link color="primary" href="#" onClick={preventDefault}>
          See more Prospects
    </Link>*/}
      </div>
    </React.Fragment>
    )
  }

  const mapStateToProps = (state) => {
//console.log("st",state.firestore.ordered.leads);
    return {
      leads: state.firestore.ordered.leads,
      auth: state.firebase.auth,
      
      //notifications: state.firestore.ordered.notifications
    }
  }
  


  export default compose(
    connect(mapStateToProps, ),
    firestoreConnect((props) => {
      if (!props.auth.uid) return []
      return [
        {
          collection: 'leads',
          where: [
            ['authorId', '==', props.auth.uid]
          ]
        }
      ]
    }
    )
  )(Order)


  {/*export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => {
      if (!props.uid) return []
      if (!props.selectedCategory) return []
      return [
        {
          collection: 'leads',
          where: [
            //['uid', '==', props.uid],
            //['category', '==', props.selectedCategory]
          ],
          orderBy: ['createdAt', 'desc']
        }
      ]
    }
    )
)(ProjectList)*/}


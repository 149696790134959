import React from "react";
import { Link } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {
    Select,
    InputLabel,
    
    FormControl,
    
  } from "@material-ui/core";
import { updateProfile } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'
import { useFormik,Formik,Form, Field } from 'formik';
import * as yup from 'yup';
import MuiPhoneNumber from "material-ui-phone-number";
import MenuItem from '@material-ui/core/MenuItem';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Box from '@material-ui/core/Box';
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { FormikTextField } from 'formik-material-fields';
import { toast, ToastContainer, MDBContainer, MDBBtn } from 'mdbreact';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
      },
  }));

const Msg1 = () => {
    const today = new Date();
    const curHr = today.getHours();
    if (curHr < 12) {
        return 'Good morning';
    } else if (curHr < 18) {
      return 'Good afternoon';
      //console.log('good afternoon')
    } else {
      return 'Good evening';
      //console.log('good evening')
    }
  
    return " ";
}

const Welcome = (props) => {
    const { user, auth } = props;
    const classes = useStyles();
  if(user){
  const fullname = user.fname+" " +user.lname;

  
  //console.log(msg1);
  return (
   <div>
         <Grid container spacing={3} justify="space-between">
            {/* Chart */}
            <Box display={{ xs: 'block', md: 'none' }}>
  <Typography ariant="h2">Hello, {user.fname}</Typography>
</Box>
    <Box display={{ xs: 'block', md: 'none' }}>
    <Fab component={Link} to="/add-prospects" color="primary" aria-label="add">
        <AddIcon />
      </Fab>
</Box>

<Grid item xs={8} md={4} lg={4} >
<Box display={{ xs: 'none', md: 'block' }}>
                <Typography variant="p">OVERVIEW</Typography>
                <Typography variant="h5"> <Msg1></Msg1>, {fullname}</Typography>
                <Typography variant="p">Here's what's happening with your activities today
</Typography>
</Box>
                </Grid>

           
                <Grid item xs={4} md={4} lg={4}>
         
          <div style={{float:"right"}}>
          <Box display={{ xs: 'none', md: 'block' }}>
      <Fab variant="extended" component={Link} to="/add-referrals" color="primary" aria-label="add">
        <AddIcon className={classes.extendedIcon}/> 
        Add referrals
      </Fab>   
      </Box>
      </div>
                </Grid>
                </Grid>
   </div>
    );
  }else{
      return (
          <Typography>Loading</Typography>
      )
  }

};

const mapStateToProps = (state, ownProps) => {
  console.log("state",state.firebase.auth);
  
  const id =   state.firebase.auth.uid;
  const users = state.firestore.data.users;
  //console.log("state1",users[id] );
  const user = users ? users[id] : null
  return {
    user: user,
    auth: state.firebase.auth,
    id:id
  }
}
const mapDispatchToProps = dispatch => {
    return {
        updateProfile: (user) => dispatch(updateProfile(user))
    }
  }
export default withRouter(compose(
  connect(mapStateToProps,mapDispatchToProps),
  firestoreConnect([{
    collection: 'users'
  }])
)(Welcome))


import React, {useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';
import Header from './components/layout/Header';
import Dashboard from './components/dashboard/Dashboard';
import LeadDetails from './components/leads/LeadDetails';
import SignIn from './components/auth/SignIn'
import SignUp from './components/auth/SignUp'

import Forget from './components/auth/ForgetPassword'
import { connect } from 'react-redux'

import DashboardUi from './components/dashboardui/dashboard'
import EditProspect from './components/dashboardui/EditProspect'
import AddProspect from './components/dashboardui/AddProspect'
import AddReferral from './components/dashboardui/AddReferral'
import EditReferral from './components/dashboardui/EditReferral'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Prospects1 from './components/dashboardui/Prospects'
import ViewPayments from './components/dashboardui/ViewPayments'
import ViewProspects from './components/dashboardui/ViewProspects'
import ViewReferral from './components/dashboardui/ViewReferral'
import guestReferral from './components/referral/referral'
import Profile from './components/dashboardui/Profile'


const App = props => {
  const { auth } = props;
  const [darkState] = useState(false);
  const palletType = darkState ? "dark" : "light";
  const darkTheme = createTheme({
    palette: {
      type: palletType,
    }
  });
  //const handleThemeChange = () => {
   // setDarkState(!darkState);
  //};
  
  return (
    <BrowserRouter>
    {auth.uid ? (




<ThemeProvider theme={darkTheme}>
<Switch>
  
  <Route exact path='/' component={auth.uid ? DashboardUi : Header}/>
  <Route exact path='/dashboard' component={ DashboardUi}/>
  <Route exact path='/prospects' component={Prospects1} />
  <Route exact path='/add-prospects' component={AddProspect} />
  <Route exact path='/payment-dashboard' component={ViewPayments} />
  <Route exact path='/prospects-dashboard' component={ViewProspects} />
  <Route exact path='/referral-dashboard' component={ViewReferral} />
  <Route exact path='/add-referrals' component={AddReferral} />
  <Route path='/prospects/edit/:id' component={EditProspect} />
  <Route path='/referral/edit/:id' component={EditReferral} />
  <Route path='/my-profile' component={Profile} />


</Switch>
</ThemeProvider>




) : (
  <div className="App">
  <Switch>
  
        <Route exact path='/' component={auth.uid ? Dashboard : Header}/>
        
     
        <Route path='/leads/:id' component={LeadDetails} />
        <Route path='/login' component={SignIn} />
        <Route path='/register' component={SignUp} />
        <Route path='/forgot' component={Forget} />
        <Route path='/invite/:id' component={guestReferral} />
  
  </Switch>
  </div>
  )}
     
    </BrowserRouter>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  }
}



export default connect(mapStateToProps)(App)

//export default App;

import React from 'react';
import { MDBBadge, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBRow, MDBCol, MDBView, MDBIcon } from 'mdbreact';

const CardExample = ({lead}) => {
  return (

      <MDBCol md='4'>
        <MDBCard wide cascade>


          <MDBCardBody cascade className='text-center'>
            <MDBCardTitle className='card-title'>
              <strong>{lead.name}</strong>
            </MDBCardTitle>

            <p className='font-weight-bold blue-text'>Email: Photographer</p>
            <p className='font-weight-bold blue-text'>City: Photographer</p>
            <p className='font-weight-bold blue-text'>Stream: Photographer</p>
            <p className='font-weight-bold blue-text'>Passout Year: Photographer</p>
            <MDBBadge pill color="primary">
                      BEST
                    </MDBBadge>
          
          </MDBCardBody>
        </MDBCard>
      </MDBCol>

      
  )
}

export default CardExample;
import React from 'react';

const LeadDetails = () =>{
    return(
        <div>
            <h1>Helloo</h1>
        </div>
    )
}

export default LeadDetails;
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  ToastContainer,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBView,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBFormInline,
  MDBAnimation
} from 'mdbreact';
import './ClassicFormPage.css';
import SignIn from './SignedInLinks';
import SignOut from './SignedOutLinks';
import logo from '../../assets/img/EdImmigo-Logo-180x54-2.png'
import { connect } from 'react-redux'

class ClassicFormPage extends React.Component {
    constructor(props) {
        super(props);
    
        console.log(this.props)
    }
  state = {
    collapseID: ''
  };

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }));

  componentDidMount() {
    document.querySelector('nav').style.height = '65px';
  }

  componentWillUnmount() {
    document.querySelector('nav').style.height = 'auto';
  }

  render() {
    const { collapseID } = this.state;
   
    const { auth, profile } = this.props;
   console.log("test",auth);
  const links = auth.uid ? <SignIn profile={profile} /> : <SignOut />;
    const overlay = (
      <div
        id='sidenav-overlay'
        style={{ backgroundColor: 'transparent' }}
        onClick={this.toggleCollapse('navbarCollapse')}
      />
    );
    return (
      <div id='classicformpage1'>
        
         <ToastContainer
        hideProgressBar={true}
        newestOnTop={true}
        autoClose={5000}
      />
          <div>
            <MDBNavbar
              dark
              expand='md'
              scrolling
              fixed='top'
              style={{ }}
            >
              <MDBContainer>
                <MDBNavbarBrand>
                <a href="/"><img
                    src={logo}
                    
                    alt=''
                    loading='lazy'
                />
                </a>
                </MDBNavbarBrand>
                <MDBNavbarToggler
                  onClick={this.toggleCollapse('navbarCollapse')}
                />
                <MDBCollapse id='navbarCollapse' isOpen={collapseID} navbar >
                    <MDBNavbarNav right pills color="unique"></MDBNavbarNav>
                    {auth.uid ? <SignIn profile={profile} /> : <SignOut />}
             
                </MDBCollapse>
              </MDBContainer>
            </MDBNavbar>
            {collapseID && overlay}
          </div>
  

      </div>
    );
  }
}
const mapStateToProps = (state) => {
    // console.log(state);
    return{
      auth: state.firebase.auth,
      profile: state.firebase.profile
    }
  }
//export default ClassicFormPage;
export default connect(mapStateToProps)(ClassicFormPage)
import React from 'react'
import { NavLink } from 'react-router-dom';
import {
    MDBNavbarNav,
    MDBNavItem,
    MDBNavLink
  } from 'mdbreact';
  import Avatar from 'react-avatar';


const SignedOutLinks = (props) => {
    const {links } = window.location.pathname ;
    console.log(props.match);
    console.log(props.history);

  return (
    <div>
            <MDBNavbarNav right pills color="unique" >
                    <MDBNavItem >
                      <MDBNavLink to='/' >Home</MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem >
                      <MDBNavLink to='/register'>Sign Up</MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to='/login'>Sign In</MDBNavLink>
                    </MDBNavItem>
       
            </MDBNavbarNav>
    </div>
  )
}

export default SignedOutLinks
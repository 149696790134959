import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ForgotForm from './ForgotForm';
import {

  MDBMask,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBView,
  MDBContainer,
  MDBCard,
  MDBCardBody,

  MDBAnimation
} from 'mdbreact';
import './custom.css';
import Footer from '../layout/Footer';
import Nav from '../layout/Navbar';

import './custom.css';
import logo from '../../assets/img/EdImmigo-Logo-180x54-2.png'

class ForgotPassword extends React.Component {
  state = {
    collapseID: ''
  };

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }));

  componentDidMount() {
    document.querySelector('nav').style.height = '65px';
  }

  componentWillUnmount() {
    document.querySelector('nav').style.height = 'auto';
  }

  render() {
    const { collapseID } = this.state;
    const overlay = (
      <div
        id='sidenav-overlay'
        style={{ backgroundColor: 'transparent' }}
        onClick={this.toggleCollapse('navbarCollapse')}
      />
    );
    return (
      <div id='classicformpage'>
          <Nav></Nav>
       
        <MDBView>
          <MDBMask className='d-flex justify-content-center align-items-center gradient' />
          <MDBContainer
            style={{ height: '100%', width: '100%', paddingTop: '10rem' }}
            className='mt-5  d-flex justify-content-center align-items-center'
          >
            <MDBRow>
              <MDBAnimation
                type='fadeInLeft'
                delay='.3s'
                className='white-text text-center text-md-left col-md-6 mt-xl-5 mb-5'
              >
                <h1 className='h1-responsive font-weight-bold'>
                EdSquad - India's First Highest-Rewarding Campus Ambassadors Program. 
                </h1>
                <hr className='hr-light' />
                <h6 className='mb-4'>
                Become a Student Entrepreneur by joining as Campus Ambassador with Ed & ImmiGo. Start Earning From Your Student Days and experience professional career from your college days.
Start making a great impact in your career From Today.
#EdSquad
                </h6>
              
              </MDBAnimation>

              <MDBCol md='6' xl='5' className='mb-4'>
                <MDBAnimation type='fadeInRight' delay='.3s'>
                  <MDBCard id='classic-card'>
                    <MDBCardBody className='white-text'>
                      <h3 className='text-center'>
                        <MDBIcon icon='user' /> Reset Password:
                      </h3>
                      <hr className='hr-light' />
                    
                      <ForgotForm ></ForgotForm>
                             </MDBCardBody>
                  </MDBCard>
                </MDBAnimation>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBView>

       <Footer />
      </div>
    );
  }
}

export default ForgotPassword;
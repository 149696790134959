const initState = {
  leads: [
    {id: '1', title: 'help me find peach', content: 'blah blah blah'},
    {id: '2', title: 'collect all the stars', content: 'blah blah blah'},
    {id: '3', title: 'egg hunt with yoshi', content: 'blah blah blah'}
  ]
}

const leadReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CREATE_LEAD':
      console.log('create lead', action.lead);
      return state;
      case 'UPDATE_LEAD':
        console.log('create lead', action.lead);
        return state;
    default:
      return state;
  }
};

export default leadReducer;
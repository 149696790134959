export const createReferral = (referral) => {
    
    return (dispatch, getState, {getFirestore}) => {
      const firestore = getFirestore();
      const profile = getState().firebase.profile;
      const authorId = getState().firebase.auth.uid;
      //console.log("lead",profile);
      firestore.collection('referrals').add({
        ...referral,
        authorFirstName: profile.fname,
        authorLastName: profile.lname,
        status:'Pending',
        authorId: authorId,
        authorEmail:profile.email,
        createdAt: new Date()
      }).then(() => {
        dispatch({ type: 'CREATE_REFERRAL_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'CREATE_REFERRAK_ERROR' }, err);
      });
    }
  };

  export const guestReferral = (referral) => {
    
    return (dispatch, getState, {getFirestore}) => {
      const firestore = getFirestore();
      //const profile = getState().firebase.profile;
      //const authorId = getState().firebase.auth.uid;
      //console.log("lead",profile);
      firestore.collection('referrals').add({
        ...referral,
        //authorFirstName: profile.fname,
       // authorLastName: profile.lname,
        //authorId: authorId,
        status:'Pending',
        createdAt: new Date()
      }).then(() => {
        dispatch({ type: 'GUEST_REFERRAL_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'GUEST_REFERRAL_ERROR' }, err);
      });
    }
  };

  export const updateReferral = (referral) => {
    
    return (dispatch, getState, {getFirestore}) => {
      const firestore = getFirestore();
      const profile = getState().firebase.profile;
      const authorId = getState().firebase.auth.uid;
      //console.log("lead",profile);
  
      firestore.collection('referrals').doc(referral.id).update({
        ...referral,
        authorFirstName: profile.fname,
        authorLastName: profile.lname,
        authorId: authorId,
        updatedAt: new Date()
      }).then(() => {
        dispatch({ type: 'UPDATE_REFERRAL_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'UPDATE_REFERRAK_ERROR' }, err);
      });
    }
  };
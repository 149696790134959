import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {
    Select,
    InputLabel,
    
    FormControl,
    
  } from "@material-ui/core";
import { updateLead } from '../../store/actions/leadActions'
import { Redirect } from 'react-router-dom'
import { useFormik,Formik,Form, Field } from 'formik';
import * as yup from 'yup';
import MuiPhoneNumber from "material-ui-phone-number";
import MenuItem from '@material-ui/core/MenuItem';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Box from '@material-ui/core/Box';
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { FormikTextField,FormikSelectField } from 'formik-material-fields';
import { toast, ToastContainer } from 'mdbreact';


const validationSchema = yup.object({
    name: yup
    .string('Enter your Fulle Name')
    .required('Full Name is required'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    mobile: yup
      .string('Enter your contact number')
      .min(10, 'Contact number should be of minimum 10 digits length')
      .min(10, 'Contact number should be of minimum 10 digits length')
      .required('Contact number is required'),
    
      college: yup
      .string('Enter your College Name')
      
      .required('College Name is required'),
      branch: yup
      .string('Enter branch name')
 
      .required('Branch name is required'),
      passout: yup
      .string('Enter year of passout')
 
      .required('year of passout is required'),
      destination: yup
      .string('Enter Study Abroad Destination')
 
      .required('Study Abroad Destination is required'),
      current: yup
      .string('Enter Current Year')
 
      .required('Current Year is required'),
      planning: yup
      .string('Enter Planning in the year')
 
      .required('Planning in the year is required'),
      preferred: yup
      .string('Enter Preferred Study Level')
 
      .required('Preferred Study Level is required'),
      proficiency: yup
      .string('Appeared any English Proficiency Test')
 
      .required('Appeared any English Proficiency Test is required'),
      exam: yup
      .string('Enter Appeared any of the below exams?')
 
      .required('This field required'),
      comments: yup
      .string('Enter Comments')
 
      .required('Comment field required'),
 
      
  });
  const CustomizedSelectForFormik = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;
  
    return (
      <Select
        name={name}
        value={value}
        onChange={e => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };
const CreateLead = (props,prop) => {
    const { lead, auth } = props;
 console.log("leads",lead);
 
    if (lead) {
        const { lead, auth,id } = props;
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        proficiency:lead.proficiency,
        name:lead.name,
        email:lead.email,
        mobile:lead.mobile,
        college:lead.college,
        branch:lead.branch,
     
        passout:lead.passout,
        current:lead.current,
        destination:lead.destination,
        planning:lead.planning,
        preferred:lead.preferred,
        exam:lead.exam,
        comments:lead.comments,
        id:id,
        createdAt:lead.createdAt

      }}
      validationSchema= {validationSchema}
      onSubmit={(values, actions) => {
        props.updateLead(values);
        toast.success('Successfully updated', {
            closeButton: false
          });
        //alert("values:" + JSON.stringify(values));
      }}
    >

<Form>

<FormControl fullWidth>

 <FormikTextField
      name="name"
      label="Full Name"
      margin="normal"
      fullWidth
    />
</FormControl>

<FormikTextField
      name="email"
      label="Email"
      margin="normal"
      fullWidth
    />
     <FormikTextField
      name="mobile"
      label="Mobile"
      margin="normal"
      fullWidth
    />
      <FormikTextField
      name="college"
      label="College"
      margin="normal"
      fullWidth
    />
    
      <FormikTextField
      name="branch"
      label="Branch / Stream"
      margin="normal"
      fullWidth
    />
      <FormikTextField
      name="passout"
      label="Passout"
      margin="normal"
      fullWidth
    />


  <FormikSelectField
      name="current"
      label="Current Year"
      margin="normal"
      options={[
        { label: '1st Year', value: '1st Year' },
        { label: '2nd Year', value: '2nd Year' },
        { label: '3rd Year', value: '3rd Year' },
        { label: '4th Year', value: '4th Year' }
      ]}
      fullWidth
      
    />


<FormikSelectField
      name="destination"
      label="Study Abroad Destination"
      margin="normal"
      options={[
        { label: 'USA', value: 'USA' },
        { label: 'Canada', value: 'Canada' },
        { label: 'UK', value: 'UK' },
        { label: 'Australia', value: 'Australia' },
        { label: 'Germany', value: 'Germany' },
        { label: 'NewZeland', value: 'NewZeland' },
        { label: 'Singapore', value: 'Singapore' },
        { label: 'Italy', value: 'Italy' },
        { label: 'Others', value: 'Others' },
      ]}
      fullWidth
    />
   <FormikSelectField
      name="planning"
      label="Planning in the year"
      margin="normal"
      options={[
        { label: '2021', value: '2021' },
        { label: '2022', value: '2022' },
        { label: '2023', value: '2023' },
        { label: '2024', value: '2024' },
        { label: '2025', value: '2025' },

      ]}
      fullWidth
    />


<FormikSelectField
      name="preferred"
      label="Preferred Study Level"
      margin="normal"
      options={[
        { label: 'Bachelors', value: 'Bachelors' },
        { label: 'Masters', value: 'Masters' },
        { label: 'PHD', value: 'PHD' },
        { label: 'Certifications', value: 'Certifications' },
        { label: 'Short Term Diplama', value: 'Short Term Diplama' },

      ]}
      fullWidth
    />


<FormikSelectField
      name="proficiency"
      label="Appeared any English Proficiency Test?"
      margin="normal"
      options={[
        { label: 'IELTS', value: 'IELTS' },
        { label: 'TOEFL', value: 'TOEFL' },
        { label: 'PTE', value: 'PTE' },
        { label: 'Others', value: 'Others' },
        { label: 'No', value: 'No' },

      ]}
      fullWidth
    />


<FormikSelectField
      name="exam"
      label="Appeared any of the below exams?"
      margin="normal"
      options={[
        { label: 'SAT', value: 'SAT' },
        { label: 'GMAT', value: 'GMAT' },
        { label: 'LSAT', value: 'LSAT' },
        { label: 'GRE', value: 'GRE' },
        { label: 'MCAT', value: 'MCAT' },
        { label: 'Others', value: 'Others' },
        { label: 'Not Yet', value: 'Not Yet' },

      ]}
      fullWidth
    />



<FormikTextField
      name="comments"
      label="Comment"
      margin="normal"
      fullWidth
      multiline
      rows={10}
    />

<Box m={2} pt={3}></Box>
<Button color="primary" variant="contained" fullWidth type="submit">
Save
</Button>
</Form>

       </Formik>
  );



} else {
    return (
      <div className="container center">
        <p>Loading project...</p>
      </div>
    )
  }

};

const mapStateToProps = (state, ownProps) => {
  console.log("state",state);
  const id = ownProps.match.params.id;
  const leads = state.firestore.data.leads;
  const lead = leads ? leads[id] : null
  return {
    lead: lead,
    auth: state.firebase.auth,
    id:id
  }
}
const mapDispatchToProps = dispatch => {
    return {
        updateLead: (lead) => dispatch(updateLead(lead))
    }
  }
export default withRouter(compose(
  connect(mapStateToProps,mapDispatchToProps),
  firestoreConnect([{
    collection: 'leads'
  }])
)(CreateLead))


import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {
    Select,
    InputLabel,
    
    FormControl,
    
  } from "@material-ui/core";
import { updateProfile } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'
import { useFormik,Formik,Form, Field } from 'formik';
import * as yup from 'yup';
import MuiPhoneNumber from "material-ui-phone-number";
import MenuItem from '@material-ui/core/MenuItem';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Box from '@material-ui/core/Box';
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { FormikTextField } from 'formik-material-fields';
import { toast, ToastContainer, MDBContainer, MDBBtn } from 'mdbreact';


const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    mobile: yup
      .string('Enter your contact number')
      .min(10, 'Contact number should be of minimum 8 digits length')
      .min(10, 'Contact number should be of minimum 10 digits length')
      .required('Contact number is required'),
  });
  const CustomizedSelectForFormik = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;
  
    return (
      <Select
        name={name}
        value={value}
        onChange={e => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };
const updateReferralFrom = (props,prop) => {
    const { user, auth } = props;
 console.log("leads",user);
 
    if (user) {
        const { user, auth,id } = props;
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
    
        fname:user.fname,
        lname:user.lname,
        email:auth.email,
        mobile:user.mobile,
        college:user.college,
        city:user.city,
   
        stream:user.stream,
     
        passOut:user.passOut,
      
        id:id,
        createdAt:user.createdAt

      }}
      onSubmit={(values, actions) => {
        props.updateProfile(values);
        toast.success('Successfully updated', {
            closeButton: false
          });
        //alert("values:" + JSON.stringify(values));
      }}
    >
      <Form>

        <FormControl fullWidth>
      
         <FormikTextField
              name="fname"
              label="First Name"
              margin="normal"
              fullWidth
            />
        </FormControl>
        <FormControl fullWidth>
      
      <FormikTextField
           name="lname"
           label="Last Name"
           margin="normal"
           fullWidth
         />
     </FormControl>

        <FormikTextField
              name="email"
              label="Email"
              
              margin="normal"
              fullWidth
            />
             <FormikTextField
              name="mobile"
              label="Mobile"
              margin="normal"
              fullWidth
            />
              <FormikTextField
              name="city"
              label="City"
              margin="normal"
              fullWidth
            />
              
              <FormikTextField
              name="college"
              label="College"
              margin="normal"
              fullWidth
            />
            
              <FormikTextField
              name="stream"
              label="Branch / Stream"
              margin="normal"
              fullWidth
            />
              <FormikTextField
              name="passOut"
              label="Passout"
              margin="normal"
              fullWidth
            />




        
  
       <Box m={2} pt={3}></Box>
    <Button color="primary" variant="contained" fullWidth type="submit">
      Update
    </Button>
      </Form>
    </Formik>
  );



} else {
    return (
      <div className="container center">
        <p>Loading project...</p>
      </div>
    )
  }

};

const mapStateToProps = (state, ownProps) => {
  console.log("state",state.firebase.auth);
  
  const id =   state.firebase.auth.uid;
  const users = state.firestore.data.users;
  //console.log("state1",users[id] );
  const user = users ? users[id] : null
  return {
    user: user,
    auth: state.firebase.auth,
    id:id
  }
}
const mapDispatchToProps = dispatch => {
    return {
        updateProfile: (user) => dispatch(updateProfile(user))
    }
  }
export default withRouter(compose(
  connect(mapStateToProps,mapDispatchToProps),
  firestoreConnect([{
    collection: 'users'
  }])
)(updateReferralFrom))


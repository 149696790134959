import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";

const FooterPage = () => {
  return (
    <MDBFooter style={{background:"#424f95"}}className="font-small">
         <div className="footer-copyright text-center py-3">
        <MDBContainer fluid style={{background:"#424f95 !important"}}>
          &copy; {new Date().getFullYear()} Copyright: <a href="https://www.edandimmigo.com"> edandimmigo.com </a>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}

export default FooterPage;
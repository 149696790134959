import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {Helmet} from "react-helmet";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ReForm from './referralForm'
import logo from '../../assets/img/Images-1.jpg'
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { toast, ToastContainer, MDBContainer, MDBBtn } from 'mdbreact';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
      EdSquad
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide() {
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
        <ToastContainer
        hideProgressBar={true}
        newestOnTop={true}
        autoClose={5000}
      />
         <Helmet>
         <title>EdSquad</title>
    <meta property="og:title" content="EdSquad - Referral System" />
    <meta
      property="og:description"
      content="EdSquad - Referral System"
    />
    <meta
      property="og:image"
      content="https://edsquad.edandimmigo.com/static/media/Images-1.30be3e9d.jpg"
    />
    <meta name="twitter:site" content="@username-example" />
    <meta
      name="twitter:card"
      content="summary || summary_large_image || player || app"
    />
    <meta name="twitter:creator" content="@username-example" />

         </Helmet>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={6} className={classes.image} />
      <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <GroupAddIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Referral Form
          </Typography>
          <ReForm></ReForm>
        </div>
      </Grid>
    </Grid>
  );
}



import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from './App';
// SETTING UP REDUX STORE
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
//import reducers from "./store/reducers/rootReducer";
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import { reduxFirestore, getFirestore } from 'redux-firestore';
import thunk from 'redux-thunk'
import rootReducer from './store/reducers/rootReducer'
// ENHANCING STORE WITH FIREBASE
//import { reactReduxFirebase } from "react-redux-firebase";
import fbConfig from "./config/fbConfig";

import reportWebVitals from './reportWebVitals';
/*const createStoreWithFirebase = compose(reactReduxFirebase(firebase))(
  createStore
);*/

const middleware = [
  thunk.withExtraArgument({ getFirestore ,getFirebase }),
  // This is where you add other middleware like redux-observable
];
const store = () => createStore(
  rootReducer,
  compose(
    applyMiddleware(...middleware),
    reduxFirestore(fbConfig),
    reactReduxFirebase(fbConfig, {userProfile: 'users', useFirestoreForProfile: true, attachAuthIsReady: true})
    //reactReduxFirebase(fbConfig)
  ),
);
/*const store = createStoreWithFirebase(
  reducers,
  {},
  //applyMiddleware(reduxThunk)
  
  applyMiddleware(thunk.withExtraArgument({getFirebase, getFirestore})),
);*/

ReactDOM.render(
  <Provider store={store()}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();



import React from "react";


import { connect } from 'react-redux'
import { forgotPass } from '../../store/actions/authActions'

//import { useFormik } from 'formik';
import { Formik,Form } from 'formik';
import * as yup from 'yup';

import { FormikTextField } from 'formik-material-fields';
import {
    toast, ToastContainer,
   
    MDBBtn,


  } from 'mdbreact';


const validationSchema = yup.object({
  
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
  
 
   
    
 
      
  });
 
const ForgotForm = (props) => {
    //const history = useHistory();
    const { auth, authError } = props;
  return (
    <div>
  
  <Formik
      enableReinitialize={true}
      initialValues={{
      
        email:'',
       
      
     
       

      }}
      validationSchema= {validationSchema}
      onSubmit={(values, {resetForm}) => {
     
          console.log("values",values)
          //props.createLead(values);
          props.forgotPass(values);
         // resetForm({ values: ''});
          //return;
        //props.updateLead(values);
      
      
        //alert("values:" + JSON.stringify(values));
      }}
      
    >
      <Form>
   
        <FormikTextField
              name="email"
              label="Email"
              margin="normal"
              InputProps={{
                style: {
                    color: "white"
                }
            }}
              fullWidth
            />
         


    

  
<div className='text-center mt-4 black-text'>

                        <MDBBtn color='indigo' type="submit">Submit</MDBBtn>
                        <div className="center red-text">
              { authError ? <p>{authError}</p> : null }
            </div>
              
                        
                        <hr className='hr-light' />
                        </div>
      </Form>
    </Formik>
 </div>
 );
};

//export default withStyles(styles)(PhoneField);
const mapStateToProps = (state) => {
    return {
      auth: state.firebase.auth,
      authError: state.auth.authError
    }
  }
  
  const mapDispatchToProps = (dispatch)=> {
    return {
        forgotPass: (creds) => dispatch(forgotPass(creds))
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ForgotForm)


import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {
    Select,
    InputLabel,
    
    FormControl,
    
  } from "@material-ui/core";
import { connect } from 'react-redux'
import { createReferral } from '../../store/actions/referralActions'
import { Redirect } from 'react-router-dom'
//import { useFormik } from 'formik';
import { useFormik,Formik,Form, Field } from 'formik';
import * as yup from 'yup';
import MuiPhoneNumber from "material-ui-phone-number";
import MenuItem from '@material-ui/core/MenuItem';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toast, ToastContainer, MDBContainer, MDBBtn } from 'mdbreact';
import { FormikTextField,FormikSelectField } from 'formik-material-fields';

const CustomizedSelectForFormik = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;
  
    return (
      <Select
        name={name}
        value={value}
        onChange={e => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };
const validationSchema = yup.object({
    name: yup
    .string('Enter your Fulle Name')
    .required('Full Name is required'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    mobile: yup
      .string('Enter your contact number')
      .min(10, 'Contact number should be of minimum 10 digits length')
      .min(10, 'Contact number should be of minimum 10 digits length')
      .required('Contact number is required'),
    
      college: yup
      .string('Enter your College Name')
      
      .required('College Name is required'),
      branch: yup
      .string('Enter branch name')
 
      .required('Branch name is required'),
      city: yup
      .string('Enter city name')
      .required('City name is required'),
      state: yup
      .string('Enter state name')
      .required('State name is required'),
      passout: yup
      .string('Enter year of passout')
 
      .required('year of passout is required'),
    
      current: yup
      .string('Enter Current Year')
 
      .required('year of passout is required'),
 
      
  });
 
const CreateReferral = (props) => {
    //const history = useHistory();
    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    const [open, setOpen] = React.useState(false);
 
  return (
    <div>
  
  <Formik
      enableReinitialize={true}
      initialValues={{
        proficiency:'',
        name:'',
        email:'',
        mobile:'',
        college:'',
        branch:'',
     city:'',
     state:'',
        passout:'',
        current:'',
        
       

      }}
      validationSchema= {validationSchema}
      onSubmit={(values, {resetForm}) => {
          console.log("values",values)
          props.createReferral(values);
          resetForm({ values: ''});
          //return;
        //props.updateLead(values);
        toast.success('Successfully Added', {
            closeButton: false
          });
        //alert("values:" + JSON.stringify(values));
      }}
      
    >
      <Form>

        <FormControl fullWidth>
      
         <FormikTextField
              name="name"
              label="Full Name"
              margin="normal"
              fullWidth
            />
        </FormControl>

        <FormikTextField
              name="email"
              label="Email"
              margin="normal"
              fullWidth
            />
             <FormikTextField
              name="mobile"
              label="Mobile"
              margin="normal"
              fullWidth
            />
              <FormikTextField
              name="college"
              label="College"
              margin="normal"
              fullWidth
            />
            <FormikTextField
              name="city"
              label="City"
              margin="normal"
              fullWidth
            />
            <FormikTextField
              name="state"
              label="State"
              margin="normal"
              fullWidth
            />
            
              <FormikTextField
              name="branch"
              label="Branch / Stream"
              margin="normal"
              fullWidth
            />
              <FormikTextField
              name="passout"
              label="Passout"
              margin="normal"
              fullWidth
            />


          <FormikSelectField
              name="current"
              label="Current Year"
              margin="normal"
              options={[
                { label: '1st Year', value: '1st Year' },
                { label: '2nd Year', value: '2nd Year' },
                { label: '3rd Year', value: '3rd Year' },
                { label: '4th Year', value: '4th Year' }
              ]}
              fullWidth
              
            />
        

        
       <Box m={2} pt={3}></Box>
    <Button color="primary" variant="contained" fullWidth type="submit">
      Save
    </Button>
      </Form>
    </Formik>
 </div>
 );
};

const mapStateToProps = (state) => {
    return {
      auth: state.firebase.auth
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        createReferral: (referral) => dispatch(createReferral(referral))
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(CreateReferral)

import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {Helmet} from "react-helmet";
import {
    Select,
    InputLabel,
    
    FormControl,
    
  } from "@material-ui/core";
  import { firestoreConnect } from 'react-redux-firebase'
  import { withRouter } from "react-router";
import { connect } from 'react-redux'
import { guestReferral } from '../../store/actions/referralActions'
import { useHistory, useParams } from 'react-router-dom'
//import { useFormik } from 'formik';
import { useFormik,Formik,Form, Field } from 'formik';
import * as yup from 'yup';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { toast, ToastContainer, MDBContainer, MDBBtn } from 'mdbreact';
import { FormikTextField,FormikSelectField } from 'formik-material-fields';
import { compose } from 'redux'

const CustomizedSelectForFormik = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;
  
    return (
      <Select
        name={name}
        value={value}
        onChange={e => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

const validationSchema = yup.object({
    name: yup
    .string('Enter your Fulle Name')
    .required('Full Name is required'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    mobile: yup
      .string('Enter your contact number')
      .min(10, 'Contact number should be of minimum 10 digits length')
      .min(10, 'Contact number should be of minimum 10 digits length')
      .required('Contact number is required'),
    
      college: yup
      .string('Enter your College Name')
      
      .required('College Name is required'),
      branch: yup
      .string('Enter branch name')
 
      .required('Branch name is required'),
      city: yup
      .string('Enter city name')
      .required('City name is required'),
      state: yup
      .string('Enter state name')
      .required('State name is required'),
      passout: yup
      .string('Enter year of passout')
 
      .required('year of passout is required'),
    
      current: yup
      .string('Enter Current Year')
 
      .required('year of passout is required'),
 
      
  });
 
const ReferralForm = (props) => {
    //const history = useHistory();
    const { id } = useParams()
    const { user } = props;
    console.log("user",user);
    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    const [open, setOpen] = React.useState(false);
 if(user){
    return (
        <div>
            {user &&
                <Typography component="h1" variant="h5">
                   Invited By {user.fname} {user.lname}
              </Typography>
    }
      <Formik
          enableReinitialize={true}
          initialValues={{
            proficiency:'',
            name:'',
            email:'',
            mobile:'',
            college:'',
            branch:'',
         city:'',
         state:'',
            passout:'',
            current:'',
            authorId:id,
            authorLastName:user.lname,
            authorFirstName:user.fname,
            authorEmail:user.email,
    
            
           
    
          }}
          validationSchema= {validationSchema}
          onSubmit={(values, {resetForm}) => {
             // console.log("values",values)
              props.guestReferral(values);
              resetForm({ values: ''});
              //return;
            //props.updateLead(values);
            toast.success('Successfully submited', {
                closeButton: false
              });
            //alert("values:" + JSON.stringify(values));
          }}
          
        >
          <Form>
    
            <FormControl fullWidth>
          
             <FormikTextField
                  name="name"
                  label="Full Name"
                  margin="normal"
                  value={id}
                  fullWidth
                />
            </FormControl>
            <FormikTextField
                  name="authorId"
                  //label="Full Name"
                 // margin="normal"
                  //fullWidth
                  value={id}
                  type="hidden"
                />
                   <FormikTextField
                  name="authorFirstName"
                  //label="Full Name"
                 // margin="normal"
                  //fullWidth
                  //alue={id}
                  type="hidden"
                />
                   <FormikTextField
                  name="authorLastName"
                  //label="Full Name"
                 // margin="normal"
                  //fullWidth
                  //value={id}
                  type="hidden"
                />
                  <FormikTextField
                  name="authorEmail"
                  //label="Full Name"
                 // margin="normal"
                  //fullWidth
                  //value={id}
                  type="hidden"
                />
            <FormikTextField
                  name="email"
                  label="Email"
                  margin="normal"
                  fullWidth
                />
                 <FormikTextField
                  name="mobile"
                  label="Mobile"
                  margin="normal"
                  fullWidth
                />
                  <FormikTextField
                  name="college"
                  label="College"
                  margin="normal"
                  fullWidth
                />
                <FormikTextField
                  name="city"
                  label="City"
                  margin="normal"
                  fullWidth
                />
                <FormikTextField
                  name="state"
                  label="State"
                  margin="normal"
                  fullWidth
                />
                
                  <FormikTextField
                  name="branch"
                  label="Branch / Stream"
                  margin="normal"
                  fullWidth
                />
                  <FormikTextField
                  name="passout"
                  label="Passout"
                  margin="normal"
                  fullWidth
                />
    
    
              <FormikSelectField
                  name="current"
                  label="Current Year"
                  margin="normal"
                  options={[
                    { label: '1st Year', value: '1st Year' },
                    { label: '2nd Year', value: '2nd Year' },
                    { label: '3rd Year', value: '3rd Year' },
                    { label: '4th Year', value: '4th Year' }
                  ]}
                  fullWidth
                  
                />
            
    
            
           <Box m={2} pt={3}></Box>
        <Button color="primary" variant="contained" fullWidth type="submit">
          Save
        </Button>
          </Form>
        </Formik>
     </div>
     );
 }else{
    return (
    <div className="container center">
    <p>Loading...</p>
  </div>
    );
 }
 
};

const mapStateToProps = (state, ownProps) => {
   // console.log("state",ownProps.match.params.id);
    const id = ownProps.match.params.id;
    const users = state.firestore.data.users;
    //console.log("state1",users);
    const user = users ? users[id] : null
    return {
      user: user,
      //auth: state.firebase.auth,
      //id:id
    }
    
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        guestReferral: (referral) => dispatch(guestReferral(referral))
    }
  }
  


  export default withRouter(compose(
    connect(mapStateToProps,mapDispatchToProps),
    firestoreConnect([{
      collection: 'users'
    }])
  )(ReferralForm))
  

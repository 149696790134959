import React, { Component } from 'react'
import ProjectList from './ProspectsItems'
//import Notifications from './Notifications'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import Od from './Orders'



class Prospects extends Component {
  render() {
    const { leads, auth } = this.props;
    console.log(leads);
    if (!auth.uid) return <Redirect to='/signin' /> 

    return (
      <div className="dashboard container">
        <div className="row">
          <div className="col s12 m6">
              <Od leads={leads}/>
            
          </div>
       
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    leads: state.firestore.ordered.leads,
    auth: state.firebase.auth,
    //notifications: state.firestore.ordered.notifications
  }
}

{/*export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) =>[
      console.log("props",props),
    { collection: 'leads', orderBy: ['createdAt', 'desc']},
    
   
  ])
)(Prospects)


const mapStateToProps = state => {
    return {
      uid: state.firebase.auth.uid,
      expenses: state.firestore.ordered.expenses
        ? state.firestore.ordered.expenses.map((t, idx) => {
          return { key: idx, description: t.description, cost: t.cost }
        })
        : [],
      selectedCategory: state.categories.selectedCategory
    }
  }
  */}
  const mapDispatchToProps = {}
  
  
  export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => {
      if (!props.uid) return []
      if (!props.selectedCategory) return []
      return [
        {
          collection: 'users',
          where: [
            //['uid', '==', props.uid],
            //['category', '==', props.selectedCategory]
          ],
          orderBy: ['createdAt', 'desc']
        }
      ]
    }
    )
  )(Prospects)
import React from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBRow, MDBCol, MDBView, MDBIcon } from 'mdbreact';
import LeadSummary from './LeadSummary';

const LeadList = ({leads}) => {
  return (
    <MDBRow>
   { leads && leads.map(lead => {
        return (
          <LeadSummary lead={lead} key={lead.id} />
        )
      })} 
    </MDBRow>
  )
}

export default LeadList;
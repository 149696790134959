import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {
    Select,
    InputLabel,
    
    FormControl,
    
  } from "@material-ui/core";
  import {
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    WhatsappIcon,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    LinkedinIcon,
    TwitterIcon,
    WorkplaceShareButton
  } from "react-share";
import { updateProfile } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'
import { useFormik,Formik,Form, Field } from 'formik';
import * as yup from 'yup';
import MuiPhoneNumber from "material-ui-phone-number";
import MenuItem from '@material-ui/core/MenuItem';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Box from '@material-ui/core/Box';
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import {ShareSocial} from 'react-share-social' 




const updateReferralFrom = (props,prop) => {
    const { user, auth,id } = props;
 console.log("leads",user);
 const style = {
    //background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    padding: '0 30px',
   // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  };
 
    if (id) {
        const { user, auth,id } = props;
  return (
      <div>   <h2>Referral via Social media</h2>
   
    <div  style={{
        display: 'flex',
        alignItems: 'center'
}}>
         <FacebookShareButton
   url={`https://edsquad.edandimmigo.com/invite/${id}`}
   quote={'Planning To Study Abroad? - Go With Ed & ImmiGo.  Your Overseas Education Process Got Easy With Ed & ImmiGo. Get Your Personal Counsellor And Book Your Free Counselling. Call 9160466446.'}
   className="Demo__some-network__share-button"
   hashtag={'#Edandimmigo,#AbroadEducationconsultant'}
 >
   <FacebookIcon size={64} round />
 </FacebookShareButton>
 <WhatsappShareButton
            url={`https://edsquad.edandimmigo.com/invite/${id}`}
            title={'Planning To Study Abroad? - Go With Ed & ImmiGo.%0%0  Your Overseas Education Process Got Easy With Ed & ImmiGo. Get Your Personal Counsellor And Book Your Free Counselling. Call 9160466446.'}
            separator=":: "
            className="Demo__some-network__share-button"
          >
            <WhatsappIcon size={64} round />
          </WhatsappShareButton>
          <LinkedinShareButton url={`https://edsquad.edandimmigo.com/invite/${id}`} className="Demo__some-network__share-button">
            <LinkedinIcon size={64} round />
          </LinkedinShareButton>
          <TwitterShareButton
            url={`https://edsquad.edandimmigo.com/invite/${id}`}
            title={'Planning To Study Abroad? - Go With Ed & ImmiGo  Your Overseas Education Process Got Easy With Ed & ImmiGo. Get Your Personal Counsellor And Book Your Free Counselling. Call 9160466446.'}
            className="Demo__some-network__share-button"
            hashtags={['Edandimmigo','AbroadEducationconsultant']}
          >
            <TwitterIcon size={64} round />
          </TwitterShareButton>
          <br/>
   
   
  
 </div>
 <ShareSocial 
     style={style}
     url ={`https://edsquad.edandimmigo.com/invite/${id}`}
     socialTypes={[]}
   />
 </div>
      );



} else {
    return (
      <div className="container center">
        <p>Loading project...</p>
      </div>
    )
  }

};

const mapStateToProps = (state, ownProps) => {
  console.log("state",state.firebase.auth);
  
  const id =   state.firebase.auth.uid;
  const users = state.firestore.data.users;
  //console.log("state1",users[id] );
  const user = users ? users[id] : null
  return {
    user: user,
    auth: state.firebase.auth,
    id:id
  }
}
const mapDispatchToProps = dispatch => {
    return {
        updateProfile: (user) => dispatch(updateProfile(user))
    }
  }
export default withRouter(compose(
  connect(mapStateToProps,mapDispatchToProps),
  firestoreConnect([{
    collection: 'users'
  }])
)(updateReferralFrom))

